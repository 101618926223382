<template>
  <div class="xingtai_Show">
    <div class="xingtai_Show_module_head">
      <div class="xingtai_Show_module_head_left">
        <div class="xingtai_Show_module_head_left_div"></div>
        <div class="xingtai_Show_module_head_left_title">会员名录</div>
      </div>
      <div class="xingtai_Show_module_head_right">
        <input type="text" />
        <div class="xingtai_Show_module_head_right_search">
          <van-icon name="search" size="20" color="#FFF" />
        </div>
      </div>
    </div>
    <div class="xingtai_Show_list">
      <div class="xingtai_Show_list_item" v-for="(item,key) in  xingtaiShowDataArr" :key="key">
        <div class="xingtai_Show_list_item_companyName">{{item.companyName}}</div>
        <div class="xingtai_Show_list_item_bottom">
          <div class="xingtai_Show_list_item_bottom_name">姓名：{{item.name}}</div>
          <div class="xingtai_Show_list_item_bottom_job">
            商会职务：{{item.job}}
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "@/plugin/importCommonUse";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue";
export default defineComponent({
  components: {
    moduleHead,
  },
  setup() {
    let route = useRoute();
    let router = useRouter();
    let init = computed(() => {});
    let xingtaiShowDataArr = reactive([
      {
        companyName: "秦皇岛格林布瑞医疗器械设备有限公司",
        job: "常务会长",
        name: "杨建忠",
      },
      {
        companyName: "秦皇岛鼎工电力工程有限公司",
        job: "执行会长兼秘书长",
        name: "耿占杰",
      },
      {
        companyName: "秦皇岛天泽环保科技有限公司",
        job: "常务第一副会长",
        name: "胡伟",
      },
      {
        companyName: "河北省第三建筑工程有限公司七分公司",
        job: "常务副会长兼监事长",
        name: "辛文秋",
      },
      {
        companyName: "秦皇岛市金时帮达汽车贸易有限公司",
        job: "常务副会长",
        name: "郭建峰",
      },
      {
        companyName: "秦皇岛华旗电缆销售有限公司",
        job: "常务副会长",
        name: "陈士卫",
      },
      {
        companyName: "秦皇岛交安交通设施工程有限公司",
        job: "常务副会长",
        name: "李建勋",
      },
      {
        companyName: "秦皇岛隆强商贸有限公司",
        job: "副会长",
        name: "郝杏强",
      },
      {
        companyName: "秦皇岛清湉洗涤服务有限公司",
        job: "副会长",
        name: "李杰",
      },
      {
        companyName: "秦皇岛北发门窗有限公司",
        job: "副会长",
        name: "孙红彬",
      },
      {
        companyName: "秦皇岛筑家装饰工程有限公司",
        job: "副会长",
        name: "韩晓成",
      },
      {
        companyName: "秦皇岛九远交通设施工程有限公司",
        job: "副会长",
        name: "刘根涛",
      },
      {
        companyName: "秦皇岛万博科技有限公司",
        job: "副会长",
        name: "邱现成",
      },
      {
        companyName: "秦皇岛悦巷酒店有限公司",
        job: "副会长",
        name: "苏成龙",
      },
      {
        companyName: "河北君德风律师事务所",
        job: "副会长",
        name: "颜翔宇",
      },
      {
        companyName: "秦皇岛励曙涂料有限公司",
        job: "副会长",
        name: "张全利",
      },
      {
        companyName: "河北博馨源环保技术有限公司",
        job: "副会长",
        name: "郭占云",
      },
      {
        companyName: "秦皇岛市宏盛报废汽车回收拆解有限公司",
        job: "理事",
        name: "贺连栋",
      },
      {
        companyName: "秦皇岛亿峰装饰工程有限公司",
        job: "理事",
        name: "贾泽瑞",
      },
      {
        companyName: "个人",
        job: "理事",
        name: "邱兴邦",
      },
      {
        companyName: "小兴台酒厂集团直销处",
        job: "理事",
        name: "高玉林",
      },
      {
        companyName: "中匠电缆有限公司",
        job: "理事",
        name: "李永恒",
      },
      {
        companyName: "河北金鸣建设工程有限公司",
        job: "理事",
        name: "赵庆敏",
      },
      {
        companyName: "秦皇岛伊水文化发展有限公司",
        job: "理事",
        name: "齐栋利",
      },
      {
        companyName: "秦皇岛博业玻璃有限公司",
        job: "理事",
        name: "李计超",
      },
      {
        companyName: "秦皇岛金时本达汽车贸易有限公司",
        job: "理事",
        name: "黄柳青",
      },
      {
        companyName: "海港区宋登帅日用品商店",
        job: "理事",
        name: "宋登帅",
      },
      {
        companyName: "秦皇岛山沲阁商贸有限公司",
        job: "理事",
        name: "张来飞",
      },
      {
        companyName: "秦皇岛元洲品界装饰工程有限公司",
        job: "理事",
        name: "安俊伟",
      },
      {
        companyName: "秦皇岛邢秦科技有限公司",
        job: "理事",
        name: "荆建豪",
      },
      {
        companyName: "秦皇岛正云环保工程有限公司",
        job: "理事",
        name: "胡立勇",
      },
      {
      companyName : "河北镌天科技开发有限公司",
      job : "副秘书长",
      name: "王苏文"
      },
      {
      companyName:"个人",
      job: "副秘书长",
      name: "谷云娜"
      },
      {
      companyName:"秦皇岛智博嘉轩商贸有限公司",
      job: "副秘书长",
      name: "刘会亮"
      },
      {
      companyName:"秦皇岛银峰电子科技有限公司",
      job: "副秘书长",
      name: "杨志峰"
      },
      {
      companyName:"河北君德风律师事务所",
      job: "副秘书长",
      name: "颜翔宇"
      },
      {
      companyName:"秦皇岛隆强商贸有限公司",
      job: "副秘书长",
      name: "郝杏强"
      },
      {
      companyName:"秦皇岛悦巷酒店有限公司",
      job: "副秘书长",
      name: "苏成龙"
      },
      {
      companyName:"河北金鸣建设工程有限公司",
      job: "副秘书长",
      name: "赵庆敏"
      },
      {
      companyName:"秦皇岛亿峰装饰工程有限公司　",
      job: "副秘书长",
      name: "贾泽瑞"
      },
      {
      companyName:"秦皇岛励曙涂料有限公司",
      job: "副秘书长",
      name: "张全利"
      },
      {
      companyName:"秦皇岛邢秦科技有限公司",
      job: "副秘书长",
      name: "荆建豪"
      },
      {
      companyName:"海港区食来坊饭店",
      job: "副秘书长",
      name: "闫保虎"
      },
      {
      companyName:"秦皇岛匠星信息技术有限公司",
      job: "监事",
      name: "耿振稳"
      },
    ]);
    let skip = (url) => {};
    return {
      xingtaiShowDataArr,
    };
  },
});
</script>
<style>
.xingtai_Show_module_head {
  height: 10%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xingtai_Show_module_head_left {
  display: flex;
  align-items: center;
  margin: 2.4%;
}
.xingtai_Show_module_head_left_div {
  height: 25px;
  width: 4px;
  background: #b34136;
  margin-right: 5px;
}
.xingtai_Show_module_head_left_title {
  white-space: nowrap;
  font-weight: 600;
}
.xingtai_Show_module_head_right {
  margin: 2.4%;
  color: #666666;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.xingtai_Show_module_head_right input {
  height: 25px;
  border-right: none;
  border: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.xingtai_Show_module_head_right_search {
  height: 25px;
  width: 45px;
  background: crimson;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.xingtai_Show_module_head_right_search:active {
  background: rgb(216, 119, 119);
  height: 25px;
  width: 45px;
}
.xingtai_Show_list {
  /* height: 78vh; */
  /* background: #B34136; */
  height: 88%;
  text-align: left;
  margin: 0 10px;
}
.xingtai_Show_list_item {
  /* background: yellowgreen; */
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.xingtai_Show_list_item_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: rgb(153, 153, 153);
}
.xingtai_Show_list_item_companyName {
  margin: 10px 0 5px 0;
  font-size: 16px;
}
</style>
